import authConfig from './auth-config';
import envConfig from './environment-config';

/**
 * Global app initialization utility class.
 */
class AppBootstrap {
  /**
   * Run initialization
   */
  async initializeEnv(memberProfile) {
    if (!memberProfile) {
      return Promise.resolve('Error');
    }
    //initialize environment and auth
    envConfig.initialize(memberProfile);

    //done
    return Promise.resolve();
  }

  /**
   * Initialize auth interceptors and handlers
   */
  async initializeAuth() {
    authConfig.initialize();

    //done
    return Promise.resolve();
  }
}

export default new AppBootstrap();
