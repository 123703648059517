import React, { Component, Fragment } from 'react';
import Sidebar from 'react-sidebar';
import HamburgerImg from '../../assets/images/hamburger-menu.svg';
import { appContext } from '../contexts/context';
import Navigation from '../initialization/countryConfig/GlobalNavRulesByCountry';
import environmentConfig from '../initialization/environment-config';
import DsInfo from './DsInfo';
import './Navigation.scss';
import NavigationOptions from './NavigationOptions';
import VersionTag from './VersionTag';

class GlobalNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      sidebarOpen: false,
      primarySidebarOpen: true,
      submenu: [],
    };
  }

  async componentDidMount() {
    let debounceTimer;
    window.addEventListener('resize', (evt) => {
      if (evt.isTrusted) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(this.debounceResize, 300);
      }
    });
  }

  async componentDidUpdate() {
    document
      .getElementsByClassName('container-fluid')[0]
      .classList.add('container-fluid-extended');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceResize());
  }

  debounceResize = () => {
    this.setState({
      sidebarOpen: false,
    });
  };

  onSetSidebarOpen = (sidebarOpen = false) => {
    this.setState({
      sidebarOpen,
      primarySidebarOpen: true,
    });
  };

  getNavbarClasses = () => {
    let className = 'navbar nonclub-navbar';
    return className;
  };

  renderSecondarySidebar = (submenu) => {
    this.setState({
      submenu,
      primarySidebarOpen: false,
    });
  };

  renderPrimarySidebar = () => {
    this.setState({
      submenu: [],
      primarySidebarOpen: true,
    });
  };

  updateBackgroundColor = () => {
    return this.state.primarySidebarOpen ? '#25282A' : '#1d2021';
  };

  goToUrl = (redirectUrl) => {
    if (redirectUrl) {
      this.context.onGoTo(redirectUrl);
    }
    this.onSetSidebarOpen(false);
  };

  render() {
    return (
      <div id='main-navbar' className={this.getNavbarClasses()}>
        <button
          id='btn-navbar-toggler'
          className='navbar-toggler'
          type='button'
          aria-controls='main-nav'
          aria-expanded='false'
          aria-label='toggle navigation'
          onClick={() => this.onSetSidebarOpen(true)}
        >
          <img
            id='menu-bar-img'
            alt='menu-barImg'
            className='menu-bar'
            src={HamburgerImg}
          />
        </button>
        <span className='club-name'>{this.context.state.title}</span>
        <div className='nav-spacer' />
        <Fragment>
          <Sidebar
            sidebar={
              <Fragment>
                <div className='sidebar-container'>
                  <Fragment>
                    {this.state.primarySidebarOpen && (
                      <Fragment>
                        <DsInfo />
                      </Fragment>
                    )}
                    <NavigationOptions
                      globalNav={
                        this.state.primarySidebarOpen
                          ? Navigation
                          : this.state.submenu
                      }
                      goTo={this.goToUrl}
                      pathName={window.location.pathname}
                      renderSecondarySidebar={this.renderSecondarySidebar}
                      renderPrimarySidebar={this.renderPrimarySidebar}
                    />
                  </Fragment>
                </div>
                <VersionTag
                  locale={environmentConfig.locale}
                  appVersion={process.env.REACT_APP_BUILD_VERSION || '0.0.0'}
                />
              </Fragment>
            }
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={{
              sidebar: {
                backgroundColor: this.updateBackgroundColor(),
                width:
                  window.innerWidth <= 576
                    ? '90%'
                    : window.innerWidth > 576 && window.innerWidth <= 768
                      ? '50%'
                      : window.innerWidth > 768 && window.innerWidth <= 1024
                        ? '40%'
                        : window.innerWidth > 1500
                          ? '20%'
                          : '25%',
              },
              overlay: { backgroundColor: 'rgba(37, 40, 42, 0.7)' },
            }}
            docked={false}
            rootClassName='navigation-side-bar'
          >
            <div />
          </Sidebar>
        </Fragment>
      </div>
    );
  }
}
GlobalNav.contextType = appContext;
export default GlobalNav;
