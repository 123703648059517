import _ from 'lodash';

export function GetLocalStorageKey(key) {
  return getWithExpiry(key);
}

export function SetLocalStorageKey(key, value, ttl = 360) {
  setWithExpiry(key, value, ttl);
}

export function ExpireLocalStorageKeys(keys) {
  expireLocalStorageKey(keys);
}

export function ClearAllLocalStorage() {
  clearAllLocalStorage();
}

export function CheckUserStorage() {
  return GetLocalStorageKey('userInformation');
}

export function SetUserStorage(user) {
  return SetLocalStorageKey('userInformation', user);
}

function setWithExpiry(key, value, ttl = 60) {
  if (typeof Storage !== 'undefined') {
    try {
      const one_sec = 1000 * 60;
      ttl = one_sec * ttl;
      const now = new Date();
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
      console.log(
        'To local storage key: ' + key + ' for ' + ttl + ' miliseconds'
      );
    } catch (error) {
      console.log('Error setting key: ', key);
      console.log(error);
    }
  } else {
    console.log('Local Storage not supported');
  }
}

function getWithExpiry(key) {
  if (typeof Storage !== 'undefined') {
    try {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();

      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      console.log('From local storage key: ', key);
      return item.value;
    } catch (error) {
      console.log('Error getting key: ', key);
      console.log(error);
      return null;
    }
  } else {
    console.log('Local Storage not supported');
  }
}

function expireLocalStorageKey(keys) {
  if (typeof Storage !== 'undefined') {
    try {
      let list = [];
      keys.map((key) => {
        Object.keys(localStorage).map((localStorageKey) => {
          if (_.includes(localStorageKey.toLowerCase(), key.toLowerCase())) {
            list.push(localStorageKey);
          }
          return key;
        });
        return key;
      });
      list.forEach((element) => {
        console.log('Expiring local storage key: ', element);
        localStorage.removeItem(element);
      });
    } catch (error) {
      console.log('Error expiring key: ', keys.Join(','));
      console.log(error);
      return null;
    }
  } else {
    console.log('Local Storage not supported');
  }
}

function clearAllLocalStorage() {
  if (typeof Storage !== 'undefined') {
    try {
      localStorage.clear();
      console.log('Clearing local storage');
    } catch (error) {
      console.log('Error clearing local storage');
      console.log(error);
      return null;
    }
  } else {
    console.log('Local Storage not supported');
  }
}
