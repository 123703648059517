import React, { Component } from 'react';
import Strings from '../localization/Localization';

class VersionTag extends Component {
  render() {
    return (
      <div className='app-version-tag'>
        <span className='locale'>
          <span>
            {Strings.GlobalNav_Locale.replace('{0}', this.props.locale)}
          </span>
        </span>
        <span className='version'>
          {Strings.GlobalNav_Version.replace('{0}', this.props.appVersion)}
        </span>
      </div>
    );
  }
}
export default VersionTag;
