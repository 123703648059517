import React, { Component } from 'react';
import Strings from '../localization/Localization';
import NavigationOptionsItem from './NavigationOptionsItem';

class NavigationOptions extends Component {
  render() {
    let globalNav = this.props.globalNav || [];
    return (
      <div className='global-nav-container' id='global-nav'>
        {globalNav &&
          globalNav.map((section, index) => (
            <div
              className={
                section.secondary
                  ? 'section-nav-container secondary'
                  : 'section-nav-container'
              }
              key={section.id}
              id={section.id}
            >
              <div className='section-title'>
                {section.secondary && index === 0 && (
                  <div className='item'>
                    <i
                      className='fas fa-arrow-left'
                      onClick={() => this.props.renderPrimarySidebar()}
                    />
                  </div>
                )}
                <span className='title'>{Strings[section.title]}</span>
              </div>
              {section &&
                section.list &&
                section.list.map((link) => (
                  <NavigationOptionsItem
                    key={link.id}
                    sectionSecondary={section.secondary}
                    allowed={true}
                    {...this.props}
                    {...link}
                  />
                ))}
            </div>
          ))}
      </div>
    );
  }
}
export default NavigationOptions;
