import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import Strings from '../localization/Localization';

class NavigationOptionsItem extends Component {
  render() {
    let url = this.props.pathName.replace(/\//g, '') || '';
    let targetUrl =
      this.props && this.props.redirectUrl
        ? this.props.redirectUrl.replace(/\//g, '')
        : '';

    targetUrl =
      targetUrl && targetUrl.split('?').length > 0
        ? targetUrl.split('?')[0]
        : '';
    url = url && url.split('?').length > 0 ? url.split('?')[0] : '';

    return (
      <div
        className={
          url.toLowerCase() === targetUrl.toLowerCase()
            ? 'section-menu active row'
            : 'section-menu row'
        }
        id={this.props.id}
        key={this.props.id}
        onClick={
          !this.props.allowed
            ? this.props.inActivePermissionHandler
            : this.props.customEvent
              ? this.props[this.props.customEvent]
              : this.props.outsideRedirection
                ? () => this.props.goToOutside(this.props.redirectUrl)
                : this.props.outsideRedirectionSameApp
                  ? () =>
                      this.props.goToNewOperationSites(this.props.redirectUrl)
                  : !isEmpty(this.props.submenu)
                    ? () =>
                        this.props.renderSecondarySidebar(this.props.submenu)
                    : () => this.props.goTo(this.props.redirectUrl)
        }
      >
        {!this.props.sectionSecondary && (
          <div className='icon col-2'>
            {this.props.icon && (
              <img
                src={this.props.icon}
                alt={this.props.id}
                style={{
                  height: this.props.dimensions.height,
                  width: this.props.dimensions.width,
                }}
              />
            )}
          </div>
        )}
        <span
          className={
            this.props.sectionSecondary && this.props.allowed
              ? 'title col-12'
              : 'title col-8'
          }
        >
          {Strings[this.props.title]}
        </span>
        {!this.props.sectionSecondary && (
          <div className='secondary-icon col-2'>
            {this.props.secondaryIcon && (
              <img src={this.props.secondaryIcon} alt={this.props.id} />
            )}
          </div>
        )}
      </div>
    );
  }
}
export default NavigationOptionsItem;
