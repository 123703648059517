import axios from 'axios';

/**
 * Global auth confi and initialization service. Registers requests interceptors and redirect on no-auth.
 */
class AuthConfig {
  currentPath = null;
  requestInterceptorHandle = null;
  responseInterceptorHandle = null;
  _cookieOptions = { path: '/' };
  sessionAvailable = true;

  /**
   * Registers a request interceptor that sets the POS_TOKEN_KEY header on all outbound XHR requests
   */
  _initAuthRequestInterceptor() {
    this.requestInterceptorHandle = axios.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  /**
   * Registes response interceptor to check for 401/unauthorized status. 307, kill switch
   */
  _initAuthResponseInterceptor() {
    this.responseInterceptorHandle = axios.interceptors.response.use(
      (response) => {
        if (response) {
          const status = response.status || 200;
          const errorStatus = [401, 307, 403];
          if (errorStatus.includes(status)) {
            return Promise.reject(`Error: ${status}`);
          }
        }
        return response;
      },
      (error) => {
        error = error.response || {};
        if (error && (error.status === 401 || error.status === 307)) {
          this.sessionAvailable = false;
        }
        return Promise.reject(error);
      }
    );
  }

  initialize() {
    this._initAuthRequestInterceptor();
    this._initAuthResponseInterceptor();
  }
}

export default new AuthConfig();
