import React, { Component } from 'react';
import { CheckUserStorage } from '../api/LocalStorageManager';

class DsInfo extends Component {
  render() {
    const userInfo = CheckUserStorage();
    return (
      <div className='account-info' id='account-info'>
        <div className='profile'>
          <div id='owner-name' className='name'>
            <span>{`Grupo Nario | ${(userInfo || {}).userName}`}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DsInfo;
