import Cookies from 'universal-cookie';
const DEFAULT_TIME_ZONE = 'America/Mexico_City';
const DEFAULT_CURRENCY_SYMBOL = '$';

class EnvironmentConfig {
  //Define base configuration strings
  POS_TOKEN_KEY = 'POS_SSO';
  LOCALE_KEY = 'locale';
  POS_LOCALE_KEY = 'RENDERING_LOCALE';
  _cookieOptions = { path: '/' };
  _countryconfig = {};

  /**
   * Gets the current locale string stored in user cookies
   */
  get locale() {
    let cookies = new Cookies();
    let locale = cookies.get(this.LOCALE_KEY) || null;
    return locale;
  }

  get countryConfig() {
    return this._countryconfig || {};
  }

  set countryConfig(config) {
    config = config || {};
    this._countryconfig = config;
  }

  get iOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  get isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPadOS|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  /**
   * Checks if browser is firefox
   */
  get isFirefox() {
    return /Firefox/i.test(navigator.userAgent);
  }

  /**
   * Gets the current timezone abbreviation
   */
  get timezoneAbbreviation() {
    return this._timezoneAbbreviation || '';
  }

  /**
   * Sets current timezone abbreviation
   */
  set timezoneAbbreviation(abbreviation) {
    this._timezoneAbbreviation = abbreviation || '';
  }

  /**
   * Sets current timezone
   */
  set timezone(timezone) {
    this._timezone = timezone || DEFAULT_TIME_ZONE;
  }

  /**
   * Gets the current timezone
   */
  get timezone() {
    return this._timezone || DEFAULT_TIME_ZONE;
  }

  /**
   * Sets current currency symbol
   */
  set currencySymbol(currencySymbol) {
    this._currencySymbol = currencySymbol || DEFAULT_CURRENCY_SYMBOL;
  }

  /**
   * Gets the current currency symbol
   */
  get currencySymbol() {
    return this._currencySymbol || DEFAULT_CURRENCY_SYMBOL;
  }

  /** Returns the browser location object. Cotaining host, origin, port, ... information.
   * @returns {*} Window location object
   */
  get browserLocation() {
    return window.location;
  }

  /**
   * Initialization method to perform dynamic config
   */
  initialize(memberProfile) {
    this._currentUser = memberProfile;
  }

  /**
   * Get Locale from querystring
   */
  getLocaleFromCookie(cookieName) {
    let cookies = new Cookies();
    return cookies.get(cookieName) || null;
  }
  /**
   * Get Locale from cookies
   */
  getLocale() {
    let locale = this.getLocaleFromCookie(this.LOCALE_KEY);
    return locale ? locale : this.getLocaleFromCookie(this.POS_LOCALE_KEY);
  }
  /**
   * Sets the current locale if not already initialized. Uses the MyHL render locale or defaults to es-MX.
   */
  async setLocale(locale) {
    let localeInitPromise = new Promise((resolve) => {
      let cookies = new Cookies();

      //check if the myHL locale is present
      let posLocale = locale;
      if (!posLocale) {
        posLocale = 'es-MX'; //we don't have a locale to use, default
        cookies.set(this.LOCALE_KEY, posLocale, this._cookieOptions);
      } else {
        posLocale = posLocale.replace('_', '-');
        cookies.set(this.LOCALE_KEY, posLocale, this._cookieOptions);
      }

      //set the strings localization flag
      resolve(posLocale);
    });

    return localeInitPromise;
  }
}

export default new EnvironmentConfig();
