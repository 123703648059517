import { isEmpty } from 'lodash';
import Moment from 'moment';
import 'moment/locale/es';
import environmentConfig from '../../initialization/environment-config';

let locale = environmentConfig.locale;

export const convertDateToStringWithFormat = (date, datFormat) => {
  if (isEmpty(locale)) {
    locale = 'es-MX';
  }
  return Moment(date)
    .locale(locale.split('-')[0] || 'es')
    .format(datFormat);
};
