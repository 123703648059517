class CountryConfigFactory {
  createConfig = async (locale) => {
    try {
      locale = locale || 'enus';
      locale = locale.toLowerCase().replace(/-/g, '');
      let { default: config } = await import('./countryConfig_' + locale);
      return config;
    } catch (error) {
      console.log(error);
    }
  };
}

export default new CountryConfigFactory();
