import axios from 'axios';
import { API_STATUS } from '../util/Constants';
import {
  ExpireLocalStorageKeys,
  GetLocalStorageKey,
  SetLocalStorageKey,
} from './LocalStorageManager';

export async function getData(
  url,
  toLocalStorage = false,
  force = false,
  config = {}
) {
  try {
    if (toLocalStorage) {
      let result = GetLocalStorageKey(url);
      if (result == null || force) {
        let response = await axios.get(url, config);
        result = { status: response.status, data: response.data };
        if (result.status === API_STATUS.OK) {
          SetLocalStorageKey(url, result);
        }
      }
      return result;
    } else {
      const response = await axios.get(url, config);
      return { status: response.status, data: response.data };
    }
  } catch (error) {
    return { status: (error.response || {}).status, data: error.data };
  }
}

export async function postData(
  url = '',
  param = {},
  clearLocalStorage = false,
  keys = [],
  config = {}
) {
  try {
    const response = await axios.post(url, param, config);
    let result = { status: response.status, data: response.data };
    if (result.status === API_STATUS.OK && clearLocalStorage) {
      ExpireLocalStorageKeys(keys);
    }
    return result;
  } catch (error) {
    return { status: (error.response || {}).status, data: error.data };
  }
}

export async function putData(url = '', param = {}, config = {}) {
  try {
    const response = await axios.put(url, param, config);
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: (error.response || {}).status, data: error.data };
  }
}

export async function deleteData(url = '', param = {}, config = {}) {
  try {
    const response = await axios.delete(url, param, config);
    return { status: response.status, data: response.data };
  } catch (error) {
    return {
      status: (error.response || {}).status,
      data: error.data,
      ...error,
    };
  }
}

export async function patchData(url = '', param = {}, config = {}) {
  try {
    const response = await axios.patch(url, param, config);
    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: (error.response || {}).status, data: error.data };
  }
}
