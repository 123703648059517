import React from 'react';
import Loading from './common/loading/Loading';
import { RouterProvider, createBrowserRouter } from 'react-router';
import routes from './routes';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/style.scss';

export const App = () => {
  const router = createBrowserRouter(routes, {
    basename: '/pos',
    v7_relativeSplatPath: true,
  });

  return <RouterProvider router={router} fallbackElement={<Loading />} />;
};

export default App;
