import { getData, postData } from './axiosMethod';
import posEndpoints from './PosEndpoints';

/*GETS*/
export async function CheckUser(userName, password) {
  let endpoint = posEndpoints('CheckUser');
  let queryString = `?userName=${userName}&password=${password}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetDashboard(branch) {
  let endpoint = posEndpoints('GetDashboard');
  let queryString = `?IdSucursal=${branch}&customerId=0&IncluirActivas=true&IncluirCanceladas=false`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetAllProductsWithPrices(
  branch,
  onlyActiveSkus = true,
  force = false
) {
  let endpoint = posEndpoints('GetAllProductsWithPrices');
  let queryString = `?IdSucursal=${branch}&OnlyActiveSkus=${onlyActiveSkus}`;
  let url = endpoint + queryString;
  let response = await getData(url, true, force);
  return response;
}

export async function GetAccounts() {
  let endpoint = posEndpoints('GetAccounts');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMovementsAccount() {
  let endpoint = posEndpoints('GetMovementsAccount');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMovementsAccountByDate(startDate, endDate) {
  let endpoint = posEndpoints('GetMovementsAccountByDate');
  let queryString = `?StartDate=${startDate}&EndDate=${endDate}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSingleProductById(id, idSucursal) {
  let endpoint = posEndpoints('GetSingleProduct');
  let queryString = `?id=${id}&IdTipoPrecio=1&IdSucursal=${idSucursal || 2}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSingleProductBySku(sku, idSucursal) {
  let endpoint = posEndpoints('GetSingleProduct');
  let queryString = `?Sku=${sku}&IdTipoPrecio=1&IdSucursal=${idSucursal || 2}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetPaymentTypes() {
  let endpoint = posEndpoints('GetPaymentTypes');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetPriceTypes() {
  let endpoint = posEndpoints('GetPriceTypes');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetPriceType(id) {
  let endpoint = posEndpoints('GetPriceType');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetBranchs() {
  let endpoint = posEndpoints('GetBranchs');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetBranch(id) {
  let endpoint = posEndpoints('GetBranch');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetUsers() {
  let endpoint = posEndpoints('GetUsers');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetUser(id) {
  let endpoint = posEndpoints('GetUser');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSubfamilies() {
  let endpoint = posEndpoints('GetSubfamilies');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetSubfamily(id) {
  let endpoint = posEndpoints('GetSubfamily');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetFamilies() {
  let endpoint = posEndpoints('GetFamilies');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetFamily(id) {
  let endpoint = posEndpoints('GetFamily');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSuppliers() {
  let endpoint = posEndpoints('GetSuppliers');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetSupplier(id) {
  let endpoint = posEndpoints('GetSupplier');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetBrands() {
  let endpoint = posEndpoints('GetBrands');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetBrand(id) {
  let endpoint = posEndpoints('GetBrand');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetProductConsignees() {
  let endpoint = posEndpoints('GetProductConsignees');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetProductConsignee(id) {
  let endpoint = posEndpoints('GetProductConsignee');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetCustomers() {
  let endpoint = posEndpoints('GetCustomers');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetCustomer(id) {
  let endpoint = posEndpoints('GetCustomer');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetPriceTypeByCustomer(customerId) {
  let endpoint = posEndpoints('GetPriceTypeByCustomer');
  let queryString = `?customerId=${customerId}`;
  let url = endpoint + queryString;
  let response = await getData(url, true);
  return response;
}

export async function GetConfiguration() {
  let endpoint = posEndpoints('GetConfiguration');
  let url = endpoint;
  let response = await getData(url, true);
  return response;
}

export async function GetSale(id) {
  let endpoint = posEndpoints('GetSale');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSalesV2(data) {
  let endpoint = posEndpoints('GetSalesV2');
  let queryString = `?idSucursal=${data.idSucursal}&customerId=${data.customerId}&startDate=${data.startDate}&endDate=${data.endDate}&incluirActivas=${data.incluirActivas}&incluirCanceladas=${data.incluirCanceladas}&priceTypeId=${data.priceTypeId}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function SaleDetailGetList(data) {
  let endpoint = posEndpoints('SaleDetailGetList');
  let queryString = `?startDate=${data.startDate}&endDate=${data.endDate}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function CheckSale(data) {
  let endpoint = posEndpoints('CheckSale');
  let queryString = `?observaciones=${data.observaciones}&customerId=${data.customerId}&BranchId=${data.idBranch}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetQuotations(data) {
  let endpoint = posEndpoints('GetQuotations');
  let queryString = `?idSucursal=${data.idSucursal}&customerId=${data.customerId}&priceTypeId=${data.priceTypeId}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetSaleDetail(id) {
  let endpoint = posEndpoints('GetSaleDetail');
  let queryString = `?id=${id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetInventoryMovements(data) {
  let endpoint = posEndpoints('GetInventoryMovements');
  let queryString = `?idSucursal=${data.idSucursal}&IdTipoMovimientoInventario=${data.IdTipoMovimientoInventario}&InventoryMovementStatusId=${data.inventoryMovementStatusId}&startDate=${data.startDate}&endDate=${data.endDate}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetInventoryReportV1(data) {
  let endpoint = posEndpoints('GetInventoryReportV1');
  let queryString = `?IdSucursal=${data.idSucursal}&DiasATomar=${data.daysToTake}&TiempoEntrega=${data.deliverTime}&IncludeCombos=${data.incluirCombos}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetInventoryReportV2(data) {
  let endpoint = posEndpoints('GetInventoryReportV2');
  let queryString = `?IdSucursal=${data.idSucursal}&DiasATomar=${data.daysToTake}&TiempoEntrega=${data.deliverTime}&IncludeCombos=${data.incluirCombos}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetInventoryReorderReport() {
  let endpoint = posEndpoints('GetInventoryReorderReport');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetSaleConsignment() {
  let endpoint = posEndpoints('GetSaleConsignment');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetProductConsigneePayments() {
  let endpoint = posEndpoints('GetProductConsigneePayments');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMlStockFull() {
  let endpoint = posEndpoints('GetMlStockFull');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMlStockWithPenalties() {
  let endpoint = posEndpoints('GetMlStockWithPenalties');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMlStockFullRequest(data) {
  let endpoint = posEndpoints('GetMlStockFullRequest');
  let queryString = `?OriginBranch=${data.originBranch}&TargetBranch=${data.targetBranch}`;
  let url = endpoint + queryString;
  let response = await postData(url);
  return response;
}

export async function GetMlSales(data) {
  let endpoint = posEndpoints('GetMlSales');
  let queryString = `?orderStatus=${data.orderStatus}&startDate=${data.startDate}T00:00:00.000-00:00&endDate=${data.endDate}T23:59:59.000-00:00&offset=${data.offset}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetMlProductsList(scroll_id) {
  let endpoint = posEndpoints('GetMlProductsList');
  let queryString = `?scroll_id=${scroll_id || ''}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetMlProducts(ids) {
  let endpoint = posEndpoints('GetMlProducts');
  let queryString = `?ids=${ids}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetMlProductVariation(mlPostId, mlVariationId) {
  let endpoint = posEndpoints('GetMlProductVariation');
  let queryString = `?mlPostId=${mlPostId}&mlVariationId=${mlVariationId}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function ProductSkuCheck(OriginalSku, Sku) {
  let endpoint = posEndpoints('ProductSkuCheck');
  let queryString = `?OriginalSku=${OriginalSku}&Sku=${Sku}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetCountings() {
  let endpoint = posEndpoints('GetCountings');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetCounting(Id) {
  let endpoint = posEndpoints('GetCounting');
  let queryString = `?Id=${Id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}

export async function GetCountingMovements(Id) {
  let endpoint = posEndpoints('GetCountingMovements');
  let queryString = `?Id=${Id}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}
/*GETS*/

/*POSTS*/

export async function SetMovementAccount(data) {
  let endpoint = posEndpoints('SetMovementAccount');
  let url = endpoint;
  let response = await postData(url, data, true, ['Account']);
  return response;
}

export async function SetProduct(data) {
  let endpoint = posEndpoints('SetProduct');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SetProductByExcel(data) {
  let endpoint = posEndpoints('SetProductByExcel');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SetSale(data) {
  let endpoint = posEndpoints('SetSale');
  let url = endpoint;
  let response = await postData(url, data, true, ['Sale', 'Product']);
  return response;
}

export async function SetQuotation(data) {
  let endpoint = posEndpoints('SetQuotation');
  let url = endpoint;
  let response = await postData(url, data, true, ['Quotation', 'Product']);
  return response;
}

export async function SetConfirmSale(data) {
  let endpoint = posEndpoints('SetConfirmSale');
  let queryString = `?Id=${data.Id}&Observaciones=${data.Observaciones}&Guia=${data.Guia}`;
  let url = endpoint + queryString;
  let response = await postData(url, data.Pagos, true, ['Sale', 'Product']);
  return response;
}

export async function CancelSale(id, reason) {
  let endpoint = posEndpoints('CancelSale');
  let queryString = `?id=${id}&reason=${reason}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Sale', 'Product']);
  return response;
}

export async function SaveInventoryIn(data) {
  let endpoint = posEndpoints('SaveInventoryIn');
  let queryString = `?BranchSelectedIn=${data.branchSelectedIn}&movementTypeSelected=${data.movementTypeSelected}&inventoryMovementStatusId=${data.inventoryMovementStatusId}&observaciones=${data.observaciones}`;
  let url = endpoint + queryString;
  let response = await postData(url, data.Movements, true, [
    'Inventory',
    'Product',
  ]);
  return response;
}

export async function SaveInventoryOut(data) {
  let endpoint = posEndpoints('SaveInventoryOut');
  let queryString = `?BranchSelectedOut=${data.branchSelectedOut}&movementTypeSelected=${data.movementTypeSelected}&inventoryMovementStatusId=${data.inventoryMovementStatusId}&observaciones=${data.observaciones}`;
  let url = endpoint + queryString;
  let response = await postData(url, data.Movements, true, [
    'Inventory',
    'Product',
  ]);
  return response;
}

export async function SaveInventoryTransfer(data) {
  let endpoint = posEndpoints('SaveInventoryTransfer');
  let queryString = `?BranchSelectedOut=${data.branchSelectedOut}&BranchSelectedIn=${data.branchSelectedIn}&inventoryMovementStatusId=${data.inventoryMovementStatusId}&comboBreakdown=${data.ComboBreakdown}&observaciones=${data.observaciones}`;
  let url = endpoint + queryString;
  let response = await postData(url, data.Movements, true, [
    'Inventory',
    'Product',
  ]);
  return response;
}

export async function ConfirmInventoryMovement(inventoryMovementId) {
  let endpoint = posEndpoints('ConfirmInventoryMovement');
  let queryString = `?inventoryMovementId=${inventoryMovementId}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Inventory', 'Product']);
  return response;
}

export async function CancelInventoryMovement(inventoryMovementId) {
  let endpoint = posEndpoints('CancelInventoryMovement');
  let queryString = `?inventoryMovementId=${inventoryMovementId}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Inventory', 'Product']);
  return response;
}

export async function SaveTnProductInfo(data) {
  let endpoint = posEndpoints('SaveTnProductInfo');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SaveMlProductInfo(data) {
  let endpoint = posEndpoints('SaveMlProductInfo');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SaveMlFullProductInfo(data) {
  let endpoint = posEndpoints('SaveMlFullProductInfo');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SaveMlStockFull(data) {
  let endpoint = posEndpoints('SaveMlStockFull');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SaveMlStockWithPenalties(data) {
  let endpoint = posEndpoints('SaveMlStockWithPenalties');
  let url = endpoint;
  let response = await postData(url, data, true, ['Product']);
  return response;
}

export async function SetUser(data) {
  let endpoint = posEndpoints('SetUser');
  let url = endpoint;
  let response = await postData(url, data, true, ['User']);
  return response;
}

export async function SetCustomer(data) {
  let endpoint = posEndpoints('SetCustomer');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetPriceType(data) {
  let endpoint = posEndpoints('SetPriceType');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration', 'Product']);
  return response;
}

export async function SetSubfamily(data) {
  let endpoint = posEndpoints('SetSubfamily');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetFamily(data) {
  let endpoint = posEndpoints('SetFamily');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetSupplier(data) {
  let endpoint = posEndpoints('SetSupplier');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetBrand(data) {
  let endpoint = posEndpoints('SetBrand');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetBranch(data) {
  let endpoint = posEndpoints('SetBranch');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration']);
  return response;
}

export async function SetConsigneePayment(data) {
  let endpoint = posEndpoints('SetConsigneePayment');
  let url = endpoint;
  let response = await postData(url, data);
  return response;
}

export async function UpdateConfiguration(data) {
  let endpoint = posEndpoints('UpdateConfiguration');
  let url = endpoint;
  let response = await postData(url, data, true, ['Configuration', 'Product']);
  return response;
}

export async function DeleteQuotation(id) {
  let endpoint = posEndpoints('DeleteQuotation');
  let queryString = `?Id=${id}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Quotation', 'Product']);
  return response;
}

export async function CheckSaleList(data) {
  let endpoint = posEndpoints('CheckSaleList');
  let url = endpoint;
  let response = await postData(url, data, true);
  return response;
}

export async function ProductVisibilityUpdateSingle(Id, Show) {
  let endpoint = posEndpoints('ProductVisibilityUpdateSingle');
  let queryString = `?Id=${Id}&Show=${Show}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Product']);
  return response;
}

export async function SetCounting(Data) {
  let endpoint = posEndpoints('SetCounting');
  let url = endpoint;
  let response = await postData(url, Data);
  return response;
}

export async function ConfirmCounting(Id) {
  let endpoint = posEndpoints('ConfirmCounting');
  let queryString = `?Id=${Id}`;
  let url = endpoint + queryString;
  let response = await postData(url, {}, true, ['Product']);
  return response;
}

export async function CancelCounting(Id) {
  let endpoint = posEndpoints('CancelCounting');
  let queryString = `?Id=${Id}`;
  let url = endpoint + queryString;
  let response = await postData(url, {});
  return response;
}
/*POSTS*/

/*AUTHENTICATION ML*/
export async function GetAppId() {
  let endpoint = posEndpoints('GetAppId');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GetMlUrl() {
  let endpoint = posEndpoints('GetMlUrl');
  let url = endpoint;
  let response = await getData(url);
  return response;
}

export async function GenerateMlAccessToken(code) {
  let endpoint = posEndpoints('GenerateMlAccessToken');
  let queryString = `?Code=${code}`;
  let url = endpoint + queryString;
  let response = await getData(url);
  return response;
}
/*AUTHENTICATION ML*/
